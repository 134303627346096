import React from "react";
import { StaticQuery, graphql } from "gatsby";

import Layout from "../components/Layout";
import ArticleSmallCard from "../components/ArticleSmallCard";
import { GridList, GridListTile } from "@material-ui/core";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";

function articles({ width }) {
  const getGridListCols = () => {
    if (isWidthUp("xl", width)) {
      return 4;
    }

    if (isWidthUp("lg", width)) {
      return 3;
    }

    if (isWidthUp("md", width)) {
      return 2;
    }

    if (isWidthUp("sm", width)) {
      return 2;
    }

    return 1;
  };
  return (
    <Layout>
      <div className="page-container">
        <StaticQuery
          query={graphql`
            query {
              allStrapiArticle {
                edges {
                  node {
                    date
                    restaurants {
                      name
                    }
                    slug
                    title
                    mainImage {
                      absolutePath
                      publicURL
                      childImageSharp {
                        resize(cropFocus: CENTER, height: 400, width: 400) {
                          src
                        }
                      }
                    }
                  }
                }
              }
            }
          `}
          render={data => {
            return (
              <>
                <GridList
                  cols={getGridListCols()}
                  spacing={10}
                  cellHeight="auto"
                >
                  {data.allStrapiArticle.edges.map((aNode, aIndex) => {
                    let a = aNode.node;
                    return (
                      <GridListTile key={aIndex}>
                        <ArticleSmallCard
                          slug={a.slug}
                          title={a.title}
                          restaurantName={
                            a.restaurants.length > 0
                              ? a.restaurants[0].name
                              : ""
                          }
                          imageSrc={a.mainImage.childImageSharp.resize.src}
                        />
                      </GridListTile>
                    );
                  })}
                </GridList>
              </>
            );
          }}
        />
      </div>
    </Layout>
  );
}

export default withWidth()(articles);
